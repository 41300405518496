import React from 'react';
import Auth from 'controllers/auth';
import IssueAcquiredContainer from 'containers/IssueAcquiredContainer';
import UnsubscribeDialogue from 'components/dialogues/UnsubscribeNewsletter';
import { browserHistory } from 'react-router';

export function openIssueAcquired(issueId, nextState) {
  const onClose = () => {
    browserHistory.replace(nextState.returnUrl);
  };

  return <IssueAcquiredContainer issueId={issueId} onClose={() => onClose()} />;
}

export function openUnsubscribeNewsletter(optOutType, nextState) {
  const onClose = () => {
    browserHistory.replace(nextState.returnUrl);
  };

  return <UnsubscribeDialogue onClose={onClose} optOutType={optOutType} user={Auth.getUser()} />;
}
